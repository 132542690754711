import { Avatar, Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import erpIntegrationImage from "../../../../assets/images/services/erp-integration.png";
import processAutomationImage from "../../../../assets/images/services/process-automation.png";
import cloudMigrationImage from "../../../../assets/images/services/cloud-migration.png";
import aiDrivenImage from "../../../../assets/images/services/ai-driven.png";



const DigitalTransformationComponent = (props) => {

    const { breakpoints } = props;

    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const inView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (inView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);

    const cards = [
        { id: 1, image: erpIntegrationImage, title: 'ERP Integration', content: 'Streamline operations with robust enterprise resource planning systems.' },
        { id: 2, image: processAutomationImage, title: 'Process Automation', content: 'Streamline operations with robust enterprise resource planning systems.' },
        { id: 3, image: cloudMigrationImage, title: 'Cloud Migration', content: 'Streamline operations with robust enterprise resource planning systems.' },
        { id: 4, image: aiDrivenImage, title: 'AI-Driven Customer Insights', content: 'Streamline operations with robust enterprise resource planning systems.' },
    ];

    return (
        <div ref={sectionRef} class="container-fluid container-xxxl">
            <div class="row">
                <div class="col-12 px-md-5">
                    <div class="column-card">
                        <div class="row">
                            <div class="col-7 text-left ">
                                <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  >Why Digital Transformation?&nbsp; ___</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ height: 'auto', padding: 4, paddingTop: 3, paddingBottom: 3 }}>
                <Grid item xs={12} sx={{ textAlign: "start", pl: "0px !important" }}>
                    <Typography variant="h6" sx={{ color: "#070031", fontSize: { xs: 28, sm: 34, md: 20 }, pb: "20px", mt: -1 }}>
                        Adapt to the rapidly changing market landscape with innovative systems and customer-centric strategies.
                        Our approach focuses on delivering agility, efficiency, and growth.
                    </Typography>
                </Grid>
            </Grid>

            <Box sx={{ mb: 5 }} />

            <div class="row">
                <div class="col-12 px-md-5">
                    <div class="column-card">
                        <div class="row">
                            <div class="col-7 text-left ">
                                <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  >Our Core Solutions&nbsp; ___</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br />

            <Grid container spacing={0.5} sx={{ padding: 4}}>
                {cards.map((card, index) => (
                    <Grid item xs={12} sm={6} md={3}   key={card.id}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: index % 2 === 0 ? 'flex-start' : 'flex-end', // Align first and third cards at top, second and fourth at bottom
                            height: '250px', // Optional: Set a fixed height to see the waterfall effect
                            marginTop: index % 2 !== 0 ? '10%' : '0', // Apply margin-top to second and fourth cards (odd index)
                        }}
                    >
                        <Card sx={{ height: '100%', backgroundColor: '#153958', color: '#fff', borderRadius: 0 , boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)'}}>
                            <CardContent>
                                <Avatar
                                    alt={card.title}
                                    src={card.image}
                                    sx={{ width: 100, height: 100, mx: 'auto', mb: 2, border: '4px solid #4caf50' }}
                                />
                                <Typography sx={{fontWeight: 'bold', fontSize: 18}}>{card.title}</Typography>
                                <Typography>{card.content}</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <br /><br />

        </div>
    )
}

export default DigitalTransformationComponent;