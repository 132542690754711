import { Avatar, Box, Button, Card, CardContent, CardMedia, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import erpIntegrationImage from "../../../../assets/images/services/erp-integration.png";
import processAutomationImage from "../../../../assets/images/services/process-automation.png";
import cloudMigrationImage from "../../../../assets/images/services/cloud-migration.png";
import endToEndSupportImage from '../../../../assets/images/services/end-to-end-support.png';
import costEfficiencyImage from "../../../../assets/images/services/cost-efficiency.png";
import enhancedCollaborationImage from "../../../../assets/images/services/enhanced-collaboration.png";
import robustSecurity from "../../../../assets/images/services/robust-security.png";
import consultationStrategyImage from "../../../../assets/images/services/consultation.png";
import seamlessMigrationImage from "../../../../assets/images/services/seamless-migration.png";
import optimizationImage from "../../../../assets/images/services/optimization.png";
import businessWorldwideBanner from "../../../../assets/images/services/business-worldwide.png";


const OurExpertiseCard = ({ content }) => {
    return (
        <Grid container spacing={3} justifyContent="center">
            {content.map((item, index) => (
                <Grid item xs={12} sm={6} md={6} key={index}>
                    <Card sx={{ maxWidth: '85%', width: '90%', margin: 'auto', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)' }}>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                            <CardMedia
                            component="img"
                            height="150"
                            image={item.image}
                            alt="Office Image"
                        />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <CardContent>
                            <Grid container direction="column" alignItems="flex-start">
                                <Grid item>
                                    <b>{item.title}</b>
                                </Grid>
                                <Grid item sx={{mt: 1, textAlign: 'start'}}>
                                  <p>{item.description}</p>
                                </Grid>
                            </Grid>
                        </CardContent>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};


const CloudServicesComponent = (props) => {

    const { breakpoints } = props;

    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const inView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (inView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);

    const cloudSolutions = [
        { id: 1, image: erpIntegrationImage, title: 'Public Cloud', content: 'Scale on demand with cost-effective, shared infrastructure solutions.' },
        { id: 2, image: processAutomationImage, title: 'Private Cloud', content: 'Ensure maximum control and security with dedicated cloud environments.' },
        { id: 3, image: cloudMigrationImage, title: 'Hybrid Cloud', content: 'Combine the best of both worlds for flexibility and seamless integration.' },
    ];

    const whyChooseNextix = [
        {
            image: endToEndSupportImage,
            title: 'End-to-End Support',
            description: 'From strategy and migration to optimization, we handle it all.'
        },
        {
            image: costEfficiencyImage,
            title: 'Cost Efficiency',
            description: 'Reduce operational expenses and pay only for what you use.'
        },
        {
            image: enhancedCollaborationImage,
            title: 'Enhanced Collaboration',
            description: 'Access data anytime, anywhere for seamless teamwork.'
        },
        {
            image: robustSecurity,
            title: 'Robust Security',
            description: 'Protect sensitive information with industry-leading security measures.'
        },
    ];

    const howItWorksContent = [
        {
            image: consultationStrategyImage,
            title: 'Consultation & Strategy',
            description: 'We assess your current infrastructure and create a custom cloud roadmap.',
            link: 'link-to-digital-identity-solutions',
        },
        {
            image: seamlessMigrationImage,
            title: 'Seamless Migration',
            description: 'Minimize downtime with our proven migration methodologies.',
            link: 'link-to-digital-identity-solutions',
        },
        {
            image: optimizationImage,
            title: 'Optimization & Maintenance',
            description: 'Ongoing support to maximize performance and cost-efficiency.',
            link: 'link-to-digital-identity-solutions',
        },
    ];



    return (
        <div ref={sectionRef} class="container-fluid container-xxxl">
            <div class="row">
                <div class="col-12 px-md-5">
                    <div class="column-card">
                        <div class="row">
                            <div class="col-7 text-left ">
                                <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  >Our Cloud Solutions&nbsp; ___</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Box sx={{ mb: 5 }} />

            <Grid container>
                {cloudSolutions.map((card, index) => (
                    <Grid item xs={12} sm={6} md={4}>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            <ListItem>
                                <ListItemAvatar sx={{ minWidth: 120 }}>
                                    <Avatar
                                        sx={{
                                            width: 100,
                                            height: 100,
                                            mx: 'auto',
                                            mb: 2,
                                            border: '4px solid #4caf50',
                                            backgroundColor: '#153958',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <img
                                            src={card.image}
                                            alt={card.title}

                                            style={{
                                                width: 90,
                                                height: 90,
                                                objectFit: 'contain',

                                            }} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={card?.title} secondary={card?.content} />
                            </ListItem>
                        </List>

                    </Grid>
                ))}
            </Grid>

            <Box sx={{ mb: 5 }} />

            <div class="row">
                <div class="col-12 px-md-5">
                    <div class="column-card">
                        <div class="row">
                            <div class="col-7 text-left ">
                                <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  >Why Choose Nextix?&nbsp; ___</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br/>

            <OurExpertiseCard content={whyChooseNextix}  />

            <Box sx={{ mb: 5 }} />

            <div class="row">
                <div class="col-12 px-md-5">
                    <div class="column-card">
                        <div class="row">
                            <div class="col-7 text-left ">
                                <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  >How It Works&nbsp; ___</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br />

            <Grid container spacing={0} justifyContent="center">
                {howItWorksContent.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card sx={{ maxWidth: '85%', width: '90%', margin: 'auto', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', display: 'flex', flexDirection: 'column', height: '100%' }} >
                            <CardMedia
                                component="img"
                                height="250"
                                image={item.image}
                                alt="Office Image"
                            />
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1 }} >
                                <Grid container direction="column" alignItems="center">
                                    <Grid item>
                                        <b>{item.title}</b>
                                    </Grid>
                                    <Grid item>
                                        <p>{item.description}</p>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <Box sx={{ mb: 5 }} />

            <Box sx={{ textAlign: 'start' }}>
                <div class="container-fluid container-xxxl">
                    <div class="bannerOverlayArea pb-3 pb-md-0">
                        <div className="bannerOverlayMinimizeImage bannerFull"
                            style={{
                                backgroundImage: `url(${businessWorldwideBanner})`,
                                backgroundPosition: '50% 50%',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover'
                            }}>
                        </div>
                        <div class="bannerOverlayText col-12 pt-2 pb-3 pb-md-5 pl-md-5" >
                            <div class="bannerTextShortTitle"></div>
                            <div class="bannerTextSubTitle d-none d-md-block">
                                <Typography className="bannerTextShortTitle" sx={{ fontSize: { xs: 30, sm: 40, md: 50 } }}>Trusted By Businesses Worldwide</Typography>
                            </div>
                            <Typography className="bannerTextLongTitle d-none d-md-block" sx={{ fontSize: { xs: 28, sm: 20 }, mt: 2 }}>
                                Let us help you achieve your cloud goals. Schedule a consultation today to discover how NextIX can transform your organization.
                            </Typography>
                        </div>
                    </div>
                </div>
            </Box>
        </div>
    )
}

export default CloudServicesComponent;