import React, { useRef, useState, useEffect } from 'react';
import { Box, Typography, Avatar, Grid, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

const testimonials = [
  {
    name: "E. Apitana, HR Manager, NEXTIX",
    text: "OVCODE has helped us securely streamline our HR processes. Employees can easily apply for leaves and route approvals conveniently. We also use OVCODE to authenticate employee payslips.",
    image: "https://via.placeholder.com/100"
  },
  {
    name: "K. Go, FirstAide",
    text: "With OVCODE, our RT-PCR results are tamper-proof. OVCODE authenticates test results and convert them to verifiable documents. OVCODE password protects the test results for the privacy of our clients.",
    image: "https://via.placeholder.com/100"
  },
  {
    name: "S. Murad , Trade Facilitation Consultant",
    text: "As a project consultant, I find OVCODE's user-friendly interface and powerful features have significantly enhanced my productivity and efficiency together with the project team.",
    image: "https://via.placeholder.com/100"
  },
  {
    name: "A. Bendicio, IT Manager",
    text: "I'm impressed by OVCODE's impact. During the free trial, it transformed my work approach and created cutting-edge digital solutions. OVCODE's user-friendly interface, advanced features, and seamless integration capabilities drive innovation and efficiency. Highly recommended for IT professionals and developers seeking exceptional outcomes.",
    image: "https://via.placeholder.com/100"
  },
  {
    name: "A. Tomas, BSBA Major in Marketing Management",
    text: "As a Marketing student pursuing a Bachelor of Science degree in Business Administration, OVCODE made document signing a breeze. It's like having a simple and convenient tool on my phone to sign and verify documents. OVCODE saves me time and helps me stay organized in my studies. I highly recommend it for hassle-free document signing.",
    image: "https://via.placeholder.com/100"
  },
];

const ClientTestimonials = () => {
  const sectionRef = useRef(null);
  const sliderRef = useRef(null);
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const element = sectionRef.current;
      if (!element) return;

      const rect = element.getBoundingClientRect();
      const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

      if (isInView && !isAnimated) {
        setIsAnimated(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isAnimated]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Box sx={{ backgroundColor: '#f2f2f2', minHeight: '40vh', py: 5 }}>
      <style>
        {`
          .slick-dots {
            position: absolute;
            bottom: -25px;
            display: block;
            width: 100%;
            padding: 0;
            margin: 0;
            list-style-type: none;
            text-align: center; 
          }
        `}
      </style>

      <Box ref={sectionRef} className="container-fluid container-xxxl">
        <div class="row">
          <div class="col-12 px-md-5">
            <div class="column-card">
              <div class="row">
                <div class="col-7 text-left ">
                  <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  >Client Testimonials&nbsp; ___</Typography>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Grid container sx={{ textAlign: 'start', position: 'relative', padding: 4, paddingTop: 3, paddingBottom: 3 }} >
          <Typography sx={{ fontSize: { xs: 28, sm: 34, md: 20 } }}>Words that describe our quality and keep us motivated</Typography>
        </Grid>
        <Box sx={{ width: '80%', margin: 'auto' }}>
          <Slider {...settings} ref={sliderRef}>
            {testimonials.map((testimonial, index) => (
              <Box key={index} sx={{ textAlign: 'center', mx: 3 }}>
                <Avatar
                  alt={testimonial.name}
                  src={testimonial.image}
                  sx={{ width: 100, height: 100, mx: 'auto', mb: 2, border: '4px solid #4caf50' }}
                />
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <FormatQuoteIcon sx={{ fontSize: 40, color: '#4caf50', transform: 'scaleX(-1)', position: 'absolute', left: -50, top: -10 }} />
                  <Typography sx={{ fontSize: '1rem', color: '#070031', mb: 1 }}>
                    {testimonial.text}
                  </Typography>
                  <FormatQuoteIcon sx={{ fontSize: 40, color: '#4caf50', position: 'absolute', right: -50, bottom: -10 }} />
                </Box>
                <Typography sx={{ fontWeight: 'bold', mt: 2 }}>
                  - {testimonial.name}
                </Typography>
              </Box>
            ))}
          </Slider>

        </Box>
      </Box>
    </Box>
  );
};

export default ClientTestimonials;
