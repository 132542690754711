import { Avatar, Box, Button, Card, CardContent, CardMedia, Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import developmentImage from "../../../../assets/images/services/development.png";
import predectiveAnalyticsImage from "../../../../assets/images/services/predictive-analytics.png";
import businessIntelligenceImage from "../../../../assets/images/services/business-intelligence.png";
import dataVisualizationImage from "../../../../assets/images/services/data-visualization.png";
import dataWarehousingImage from "../../../../assets/images/services/data-warehousing.png";
import dataDrivenImage from "../../../../assets/images/services/data-driven.png";




const OurExpertiseCard = ({ content }) => {
    return (
        <Grid container spacing={3} justifyContent="center">
            {content.map((item, index) => (
                <Grid item xs={12} sm={6} md={6} key={index}>
                    <Card sx={{ maxWidth: '85%', width: '90%', margin: 'auto', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)' }}>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <CardMedia
                                    component="img"
                                    height="150"
                                    image={item.image}
                                    alt="Office Image"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CardContent>
                                    <Grid container direction="column" alignItems="flex-start">
                                        <Grid item>
                                            <b>{item.title}</b>
                                        </Grid>
                                        <Grid item sx={{ mt: 1, textAlign: 'start' }}>
                                            <p>{item.description}</p>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};



const DataAnalyticsComponent = (props) => {

    const { breakpoints } = props;

    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const inView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (inView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);

    const dataAnalyticsSolutions = [
        {
            image: predectiveAnalyticsImage,
            title: 'Predictive Analytics',
            description: 'Forecast trends and make proactive business decisions..'
        },
        {
            image: businessIntelligenceImage,
            title: 'Business Intelligence',
            description: 'Extract meaningful insights to drive strategic growth.'
        },
        {
            image: dataVisualizationImage,
            title: 'Data Visualization',
            description: 'Transform complex data into easy-to-understand visual insights.'
        },
        {
            image: dataWarehousingImage,
            title: 'Data Warehousing',
            description: 'Centralize and optimize your data for improved accessibility and performance.'
        },
    ];


    const whyChooseNextixContent = [
        {
            label: 'AI-Powered Insights –',
            description: `Leverage machine learning for accurate forecasting.`,
        },
        {
            label: 'Custom Dashboards –',
            description: 'Get tailored analytics visualizations for your business.',
        },
        {
            label: 'Scalable & Secure – ',
            description: `Handle massive data volumes with enterprise-grade security.`,
        },
        {
            label: 'Actionable Intelligence –',
            description: `Make informed decisions with confidence.`,
        }
    ];


    return (
        <div ref={sectionRef} class="container-fluid container-xxxl">
            <div class="row">
                <div class="col-12 px-md-5">
                    <div class="column-card">
                        <div class="row">
                            <div class="col-7 text-left ">
                                <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  >
                                    Why Data Analytics?&nbsp; ___
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ height: 'auto', padding: 4, paddingTop: 3, paddingBottom: 3 }}>
                <Grid item xs={12} sm={5.5} sx={{ textAlign: "start", pl: "0px !important" }}>
                    <Typography variant="h6" sx={{ color: "#070031", fontSize: { xs: 28, sm: 34, md: 20 }, pb: "20px", mt: -1 }}>
                        In today’s fast-paced digital world, businesses that leverage data effectively stay ahead of the competition.
                        Our analytics solutions help you uncover patterns, predict trends, and make informed decisions with confidence.
                    </Typography>
                </Grid>
                <Grid item xs={0} sm={0.5} />
                <Grid item xs={12} sm={6} sx={{ textAlign: "start", pl: "0px !important" }}>
                    <CardMedia
                        component="img"
                        height="350"
                        image={developmentImage}
                        alt="Office Image"
                    />
                </Grid>
            </Grid>


            <Box sx={{ mb: 5 }} />

            <div class="row">
                <div class="col-12 px-md-5">
                    <div class="column-card">
                        <div class="row">
                            <div class="col-7 text-left ">
                                <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  >Our Data Analytics Solutions&nbsp; ___</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br></br>
            <OurExpertiseCard content={dataAnalyticsSolutions} />

            <Box sx={{ mb: 5 }} />

            <div class="row">
                <div class="col-12 px-md-5">
                    <div class="column-card">
                        <div class="row">
                            <div class="col-7 text-left ">
                                <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  >How We Deliver Data-Driven Success&nbsp; ___</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <img src={dataDrivenImage} style={{width: '70%'}} />


            <Box sx={{ mb: 5 }} />

            <div class="row">
                <div class="col-12 px-md-5">
                    <div class="column-card">
                        <div class="row">
                            <div class="col-7 text-left ">
                                <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  >Why Choose NextIX for Data Analytics?&nbsp; ___</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <br />
            
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto', maxWidth: '70%', margin: 'auto' }} >
                <Stepper activeStep={whyChooseNextixContent.length - 0} orientation="vertical" sx={{ '& .MuiStepIcon-root.Mui-completed': { color: '#153958',  }, }}>
                    {whyChooseNextixContent.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: 18 }}>
                                        {step.label}
                                    </Typography>
                                    <Typography sx={{ fontSize: 18 }}>
                                        {step.description}
                                    </Typography>
                                </Box>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>

            <br />

        </div>
    )
}

export default DataAnalyticsComponent;