import React, { useEffect } from 'react';
import Banner from './banner.component';
import { Box, Link } from "@mui/material";
import CompaniesWorkWith from './companies-work-with.component';
import ClientsAndTestimonies from './clients-and-testimonies.component';
import TechnologyPartners from './technology-partners.component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import CustomSolutionsComponent from './custom-solutions.component';
import IndustrySolutionsComponent from './industry-solutions.component';

const WhatWeDoComponent = (props) => {
  const { breakpoints } = props;

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === '#custom-solutions') {
      const element = document.getElementById('custom-solutions');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        window.history.replaceState(null, '', window.location.pathname);
      }
    } else if (hash === '#success-stories') {
      const element = document.getElementById('success-stories');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        window.history.replaceState(null, '', window.location.pathname);
      }
    } 
    // else {
    //   window.scrollTo(0, 0);
    // }

  }, []);

  return (
    <Box>
      <Banner />
      <Box sx={{ mb: 5 }} />
      <IndustrySolutionsComponent />
      <Box id="custom-solutions"></Box>
      <Box sx={{ mb: 5 }} />
      {/* <CustomSolutionsComponent />
      <Box sx={{ mb: 10 }} /> */}
      <CompaniesWorkWith />
      <Box id="success-stories"></Box>
      <Box sx={{ mb: 10 }} />
      {/* <ClientsAndTestimonies />
      <Box sx={{ mb: 10 }} /> */}
      <TechnologyPartners />
      <Box sx={{ mb: 10 }} />
      <Box className="container-fluid container-xxxl">
        <div className="row">
          <div className="col-12 px-md-5">
            <div className="column-card">
              <div className="row">
                <div className="socialfollow aem-GridColumn aem-GridColumn--default--12" style={{ textAlign: 'start' }}>
                  <section className="col-12-soc" aria-label="follow us on social media" style={{ display: 'flex', alignItems: 'center', padding: '0px' }}>
                    <div className="base font-weight-normal d-inline align-middle mr-g">
                      Follow Nextix
                    </div> &nbsp;&nbsp;&nbsp;
                    <div className="soc-widget">
                      <Link target="_blank" href="https://www.facebook.com/OVCodeSwitzerlandAG/" underline="none">
                        <i><FontAwesomeIcon icon={faFacebook} /></i>
                      </Link>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default WhatWeDoComponent;
