import { Avatar, Box, Button, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import OfficeOne from '../../../../assets/images/empty-office-workplace-with-table-chair-computer.jpg';
import WhyNextixBanner from "../../../../assets/images/services/WhyNextix.png";
import HowWeDeliverBanner from "../../../../assets/images/services/HowWeDeliver.png";
import developmentImage from "../../../../assets/images/services/development.png";
import customSoftwareImage from "../../../../assets/images/services/custom-software.png";
import platformDevelopmentImage from "../../../../assets/images/services/platform-development.png";
import applicationModernizationImage from "../../../../assets/images/services/application-modernazation.png";
import enterpriseIntegrationImage from "../../../../assets/images/services/enterprise-integration.png";
import agileImage from "../../../../assets/images/services/agile.png";
import advancedImage from "../../../../assets/images/services/advanced-development.png";
import seasonedImage from "../../../../assets/images/services/seasoned-developers.png";
import scalableImage from "../../../../assets/images/services/scalable-solutions.png";
import securedImage from "../../../../assets/images/services/secure-high-performance.png";
import consultationImage from "../../../../assets/images/services/consultation-strategy.png";
import developmentTestingImage from "../../../../assets/images/services/development-testing.png";
import deploymentImage from "../../../../assets/images/services/deployment-support.png";




const OurExpertiseCard = ({ content }) => {
    return (
        <Grid container spacing={3} justifyContent="center">
            {content.map((item, index) => (
                <Grid item xs={12} sm={6} md={6} key={index}>
                    <Card sx={{ maxWidth: '85%', width: '90%', margin: 'auto', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)' }}>
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                            <CardMedia
                            component="img"
                            height="150"
                            image={item.image}
                            alt="Office Image"
                        />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                            <CardContent>
                            <Grid container direction="column" alignItems="flex-start">
                                <Grid item>
                                    <b>{item.title}</b>
                                </Grid>
                                <Grid item sx={{mt: 1, textAlign: 'start'}}>
                                  <p>{item.description}</p>
                                </Grid>
                            </Grid>
                        </CardContent>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};


const SoftwareDevelopmentComponent = (props) => {

    const { breakpoints } = props;

    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const inView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (inView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);


    // Content for the first row of cards
    const firstRowContent = [
        {
            image: customSoftwareImage,
            title: 'Custom Software Development',
            description: 'Tailored solutions designed for your specific business needs.'
        },
        {
            image: platformDevelopmentImage,
            title: 'Application Modernization',
            description: 'Upgrade and enhance legacy systems to stay ahead of the curve.'
        },
        {
            image: applicationModernizationImage,
            title: 'Platform Development',
            description: 'Build robust platforms from the ground up with agility and precision.'
        },
        {
            image: enterpriseIntegrationImage,
            title: 'Enterprise Integration',
            description: 'Seamlessly connect systems for improved efficiency and collaboration.'
        },
    ];



    const whyNextixContent = [
        {
            image: agileImage,
            title: 'Agile Methodologies',
            description: 'Rapid, iterative development ensuring flexibility and faster time-to-market.',
            link: 'link-to-digital-identity-solutions',
        },
        {
            image: advancedImage,
            title: 'Advanced Development Tools',
            description: 'Rapid, iterative development ensuring flexibility and faster time-to-market.',
            link: 'link-to-digital-identity-solutions',
        },
        {
            image: seasonedImage,
            title: 'Seasoned Developers',
            description: 'Rapid, iterative development ensuring flexibility and faster time-to-market.',
            link: 'link-to-digital-identity-solutions',
        },
        {
            image: scalableImage,
            title: 'Scalable Solutions',
            description: 'Rapid, iterative development ensuring flexibility and faster time-to-market.',
            link: 'link-to-digital-identity-solutions',
        },
        {
            image: securedImage,
            title: 'Secure & High-Performance',
            description: 'Rapid, iterative development ensuring flexibility and faster time-to-market.',
            link: 'link-to-digital-identity-solutions',
        },
    ];


    const howWeDeliverContent = [
        {
            image: consultationImage,
            title: 'Consultation & Strategy',
            description: 'Understand your challenges and define clear objectives.',
            link: 'link-to-digital-identity-solutions',
        },
        {
            image: developmentTestingImage,
            title: 'Development & Testing',
            description: 'Build, test, and refine using best practices in software engineering.',
            link: 'link-to-digital-identity-solutions',
        },
        {
            image: deploymentImage,
            title: 'Deployment & Support',
            description: 'Seamless implementation with ongoing maintenance and optimization.',
            link: 'link-to-digital-identity-solutions',
        },
    ];

    return (
        <div ref={sectionRef} class="container-fluid container-xxxl">
            <div class="row">
                <div class="col-12 px-md-5">
                    <div class="column-card">
                        <div class="row">
                            <div class="col-7 text-left ">
                                <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  >
                                    Transform Your Business with Custom Software&nbsp; ___
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ height: 'auto', padding: 4, paddingTop: 3, paddingBottom: 3 }}>
                <Grid item xs={12} sm={5.5} sx={{ textAlign: "start", pl: "0px !important" }}>
                    <Typography variant="h6" sx={{ color: "#070031", fontSize: { xs: 28, sm: 34, md: 20 }, pb: "20px", mt: -1 }}>
                        Leverage NextIX’s expertise in custom software development to create innovative solutions that drive business growth.
                        From enhancing existing applications to building entirely new platforms, we craft secure, scalable, and high-performance software tailored to your unique challenges.
                    </Typography>
                </Grid>
                <Grid item xs={0} sm={0.5} />
                <Grid item xs={12} sm={6} sx={{ textAlign: "start", pl: "0px !important" }}>
                    <CardMedia
                        component="img"
                        height="350"
                        image={developmentImage}
                        alt="Office Image"
                    />
                </Grid>
            </Grid>

            <Box sx={{ mb: 5 }} />

            <div class="row">
                <div class="col-12 px-md-5">
                    <div class="column-card">
                        <div class="row">
                            <div class="col-7 text-left ">
                                <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  >Our Expertise&nbsp; ___</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br></br>
            <OurExpertiseCard content={firstRowContent} />


            <Box sx={{ mb: 5 }} />


            <Box sx={{ textAlign: 'start' }}>
                <div class="container-fluid container-xxxl">
                    <div class="bannerOverlayArea pb-3 pb-md-0">
                        <div className="bannerOverlayMinimizeImage bannerFull"
                            style={{
                                backgroundImage: `url(${WhyNextixBanner})`,
                                backgroundPosition: '50% 50%',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover'
                            }}>
                        </div>
                    </div>
                </div>
            </Box>

            <br /><br />

            <Grid container spacing={0} justifyContent="center">
                {whyNextixContent.map((item, index) => (
                    <Grid item xs={12} sm={3} md={2} key={index}>
                        <Card sx={{ maxWidth: '85%', width: '90%', margin: 'auto', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1 }} >
                                <Avatar
                                    sx={{
                                        width: 100, 
                                        height: 100,
                                        mx: 'auto',
                                        mb: 2,
                                        border: '4px solid #4caf50',
                                        backgroundColor: '#153958',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        overflow: 'hidden', 
                                    }}
                                >

                                    <img
                                        src={item.image}
                                        alt={item.title}
                                        style={{
                                            width: 90,
                                            height: 90,
                                            objectFit: 'contain', 

                                        }}
                                    />
                                </Avatar>
                                <Grid container direction="column" alignItems="center">
                                    <Grid item>
                                        <b>{item.title}</b>
                                    </Grid>
                                    <Grid item>
                                        <p>{item.description}</p>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <Box sx={{ mb: 5 }} />


            <Box sx={{ textAlign: 'start' }}>
                <div class="container-fluid container-xxxl">
                    <div class="bannerOverlayArea pb-3 pb-md-0">
                        <div className="bannerOverlayMinimizeImage bannerFull"
                            style={{
                                backgroundImage: `url(${HowWeDeliverBanner})`,
                                backgroundPosition: '50% 50%',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover'
                            }}>
                        </div>
                    </div>
                </div>
            </Box>

            <br /><br />

            <Grid container spacing={0} justifyContent="center">
                {howWeDeliverContent.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card sx={{ maxWidth: '85%', width: '90%', margin: 'auto', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <CardMedia
                                component="img"
                                height="250"
                                image={item.image}
                                alt="Office Image"
                            />
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1 }}>
                                <Grid container direction="column" alignItems="center">
                                    <Grid item>
                                        <b>{item.title}</b>
                                    </Grid>
                                    <Grid item sx={{textAlign: 'start'}}>
                                        <p>{item.description}</p>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <Box sx={{ mb: 1 }} />

        </div>
    )
}

export default SoftwareDevelopmentComponent;