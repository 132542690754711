import React from "react";
import { Box, Typography } from "@mui/material";
import useWidth from "../../../../hooks/useWidth";
import CloudServicesBanner from "../../../../assets/images/services/CloudServices.png";
import CloudServicesComponent from "../components/cloud-services-component";


const CloudServicesScreen = () => {

    const breakpoints = useWidth();

    return (
        <Box>
            <Box sx={{ textAlign: 'start' }}>
                <div class="container-fluid container-xxxl">
                    <div class="bannerOverlayArea pb-3 pb-md-0">
                        <div className="bannerOverlayMinimizeImage bannerFull"
                            style={{
                                backgroundImage: `url(${CloudServicesBanner})`,
                                backgroundPosition: '50% -10%',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover'
                            }}>
                        </div>
                    </div>
                </div>
            </Box>

            <Box sx={{ mb: 10 }} />

            <CloudServicesComponent breakpoints={breakpoints} />

        </Box>
    )
}

export default CloudServicesScreen