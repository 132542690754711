import { Avatar, Box, Button, Card, CardContent, CardMedia, Grid, Paper, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import threatDetectionImage from '../../../../assets/images/services/threat-detection.png';
import complaianceImage from "../../../../assets/images/services/compliance.png";
import disasterRecoveyImage from "../../../../assets/images/services/disaster.png";
import securityAssesmentImage from "../../../../assets/images/services/security-assesment.png";
import implementationSecurityImage from "../../../../assets/images/services/implementation-security.png";
import threatMonitoring from "../../../../assets/images/services/threat-monitoring.png";
import incidentResponseImage from "../../../../assets/images/services/incident-response.png";
import cybersecuritySolutions from "../../../../assets/images/services/cybersecurity-solutions.png";
import secureBusinessImage from "../../../../assets/images/services/secure-business.png";



const CybersecurityComponent = (props) => {

    const { breakpoints } = props;

    const sectionRef = useRef(null);
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const element = sectionRef.current;
            if (!element) return;

            const rect = element.getBoundingClientRect();
            const inView = rect.top >= 0 && rect.bottom <= window.innerHeight;

            if (inView && !isAnimated) {
                setIsAnimated(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAnimated]);



    const cybersecurityMattersContent = [
        {
            image: threatDetectionImage,
            title: ' Threat Detection & Prevention',
            description: 'Identify risks before they become threats.'
        },
        {
            image: complaianceImage,
            title: 'Compliance & Risk Management',
            description: 'Stay compliant with security standards.'
        },
        {
            image: disasterRecoveyImage,
            title: 'Disaster Recovery & Response',
            description: 'Ensure business continuity in case of attacks.'
        },

    ];


    const secureBusinessContent = [
        {
            image: securityAssesmentImage,
            title: 'Security Assessment & Audit',
            description: 'Evaluate current vulnerabilities and security gaps to strengthen defenses against potential threats.'
        },
        {
            image: implementationSecurityImage,
            title: 'Implementation of Security Protocols',
            description: 'Deploy firewalls, encryption, and monitoring tools to enhance security and protection.'
        },
        {
            image: threatMonitoring,
            title: 'Real-Time Threat Monitoring',
            description: 'Enable continuous surveillance and AI-powered threat detection for real-time security monitoring.'
        },
        {
            image: incidentResponseImage,
            title: 'Incident Response & Recovery',
            description: 'Implement rapid mitigation and recovery strategies to minimize impact from cyber incidents.'
        },
    ];


    const whyChooseNextixContent = [
        {
            label: 'Proactive Threat Management –',
            description: `We detect and prevent threats before they cause damage.`,
        },
        {
            label: 'Enterprise-Grade Protection –',
            description:
                'Secure your business with industry-leading tools.',
        },
        {
            label: 'Customized Security Strategies –',
            description: `Solutions tailored to your organization’s needs.`,
        },
        {
            label: '24/7 Security Operations Center –',
            description: `Around-the-clock monitoring and support.`,
        }
    ];


    return (
        <div ref={sectionRef} class="container-fluid container-xxxl">
            <div class="row">
                <div class="col-12 px-md-5">
                    <div class="column-card">
                        <div class="row">
                            <div class="col-7 text-left ">
                                <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  >
                                    Why Cybersecurity Matters&nbsp; ___
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" sx={{ height: 'auto', padding: 4, paddingTop: 3, paddingBottom: 3 }}>
                <Grid item xs={12} sm={12} sx={{ textAlign: "start", pl: "0px !important" }}>
                    <Typography variant="h6" sx={{ color: "#070031", fontSize: { xs: 28, sm: 34, md: 20 }, pb: "20px", mt: -1 }}>
                        In today’s digital age, cyber threats are evolving rapidly. Businesses must stay ahead with proactive security measures to prevent breaches, protect sensitive data, and ensure compliance with industry regulations.
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Grid container spacing={0} justifyContent="center">
                        {cybersecurityMattersContent.map((item, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card sx={{ maxWidth: '85%', width: '90%', margin: 'auto', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', display: 'flex', flexDirection: 'column', height: '100%' }}>
                                    <CardMedia
                                        component="img"
                                        height="250"
                                        image={item.image}
                                        alt="Office Image"
                                    />
                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1 }}>
                                        <Grid container direction="column" alignItems="center">
                                            <Grid item>
                                                <b>{item.title}</b>
                                            </Grid>
                                            <Grid item sx={{ textAlign: 'start' }}>
                                                <p>{item.description}</p>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>


            <Box sx={{ mb: 5 }} />

            <Box sx={{ backgroundColor: "#f3f3f3" }}>
                <br />
                <div class="row">
                    <div class="col-12 px-md-5">
                        <div class="column-card">
                            <div class="row">
                                <div class="col-7 text-left ">
                                    <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  > Our Cybersecurity Solutions&nbsp; ___</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <img src={cybersecuritySolutions} style={{ marginBottom: '30px' }} />


            </Box>

            <Box sx={{ mb: 5 }} />

            <div class="row">
                <div class="col-12 px-md-5">
                    <div class="column-card">
                        <div class="row">
                            <div class="col-7 text-left ">
                                <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  >How We Secure Your Business&nbsp; ___</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br />

            <Grid container spacing={3} justifyContent="center">
                {secureBusinessContent.map((item, index) => (
                    <Grid item xs={12} sm={6} md={6} key={index}>
                        <Card sx={{ maxWidth: '85%', width: '90%', margin: 'auto', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)', display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <CardMedia
                                component="img"
                                height="250"
                                image={item.image}
                                alt="Office Image"
                            />
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', flex: 1 }}>
                                <Grid container direction="column" alignItems="center">
                                    <Grid item>
                                        <b>{item.title}</b>
                                    </Grid>
                                    <Grid item sx={{ textAlign: 'start' }}>
                                        <p>{item.description}</p>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <Box sx={{ mb: 5 }} />

            <div class="row">
                <div class="col-12 px-md-5">
                    <div class="column-card">
                        <div class="row">
                            <div class="col-7 text-left ">
                                <Typography sx={{ fontWeight: 'bold', textAlign: 'start', fontSize: 16 }} className={isAnimated ? 'typedSectionHeading goType' : 'typedSectionHeading'}  >Why Choose NextIX for Cybersecurity?&nbsp; ___</Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <br />

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'auto', maxWidth: '70%', margin: 'auto' }} >
                <Stepper activeStep={whyChooseNextixContent.length - 0} orientation="vertical" sx={{ '& .MuiStepIcon-root.Mui-completed': { color: '#153958',  }, }}>
                    {whyChooseNextixContent.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <Typography sx={{ fontWeight: 'bold', fontSize: 18 }}>
                                        {step.label}
                                    </Typography>
                                    <Typography sx={{ fontSize: 18 }}>
                                        {step.description}
                                    </Typography>
                                </Box>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>

            <br />

            <Box sx={{ mb: 5 }} />

            <Box sx={{ textAlign: 'start' }}>
                <div class="container-fluid container-xxxl">
                    <div class="bannerOverlayArea pb-3 pb-md-0">
                        <div className="bannerOverlayMinimizeImage bannerFull"
                            style={{
                                backgroundImage: `url(${secureBusinessImage})`,
                                backgroundPosition: '50% 50%',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover'
                            }}>
                        </div>
                        <div class="bannerOverlayText col-12  pt-2 pb-3 pb-md-5 pl-md-5" >
                            <div class="bannerTextShortTitle"></div>
                            <div class="bannerTextSubTitle d-none d-md-block">
                                <Typography className="bannerTextShortTitle" sx={{ fontSize: { xs: 30, sm: 40, md: 50 } }}>Secure Your Business Today</Typography>
                            </div>
                            <Typography className="bannerTextLongTitle d-none d-md-block" sx={{ fontSize: { xs: 28, sm: 34 }, mt: 2 }}>
                                "Don't wait for a breach—fortify your digital assets now."
                            </Typography>
                        </div>
                    </div>
                </div>
            </Box>



        </div>
    )
}

export default CybersecurityComponent;