import React from "react";
import { Box, Typography } from "@mui/material";
import useWidth from "../../../../hooks/useWidth";
import CybersecurityBanner from "../../../../assets/images/services/Cybersecurity.png";
import CybersecurityComponent from "../components/cybersecurity-component";


const CybersecurityScreen = () => {

    const breakpoints = useWidth();

    return (
        <Box>
            <Box sx={{ textAlign: 'start' }}>
                <div class="container-fluid container-xxxl">
                    <div class="bannerOverlayArea pb-3 pb-md-0">
                        <div className="bannerOverlayMinimizeImage bannerFull"
                            style={{
                                backgroundImage: `url(${CybersecurityBanner})`,
                                backgroundPosition: '50% 50%',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover'
                            }}>
                        </div>
                        <div class="bannerOverlayText col-12 pt-2 pb-3 pb-md-5 pl-md-5" >
                            <div class="bannerTextShortTitle"></div>
                            <div class="bannerTextSubTitle d-none d-md-block">
                                <Typography className="bannerTextShortTitle" sx={{ fontSize: { xs: 30, sm: 40, md: 50 } }}>Cybersecurity</Typography>
                            </div>
                            <Typography className="bannerTextLongTitle d-none d-md-block" sx={{ fontSize: { xs: 28, sm: 34 }, mt: 2 }}>
                                Protect your digital assets with advanced cybersecurity solutions.
                            </Typography>
                        </div>
                    </div>
                </div>
            </Box>

            <Box sx={{ mb: 10 }} />

            <CybersecurityComponent breakpoints={breakpoints} />

        </Box>
    )
}

export default CybersecurityScreen