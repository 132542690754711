import { Box, Button, Menu, MenuItem, TextField, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';



const MobileAppbarComponent = (props) => {

    const {
        mobileMoreAnchorEl,
        handleMobileMenuClose,
        handleWhoWeAreMenuOpen,
        selectedWhoWeAre,
        handleWhatWeDoMenuOpen,
        selectedWhatWeDo,
        handleLanguageMenuOpen,
        mobileMenuId,
        selectedNews,
        selectedContactUs,
        handleButtonClick,
        showSearch
    } = props;

    return (
        <Box sx={{display: 'flex'}}>

            <Menu
                anchorEl={mobileMoreAnchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                id={mobileMenuId}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={Boolean(mobileMoreAnchorEl)}
                onClose={handleMobileMenuClose}

            >
                <MenuItem >
                    <Button
                        id="demo-customized-button"
                        variant="text"
                        disableElevation
                        endIcon={<ExpandMoreIcon />}
                        onClick={handleWhoWeAreMenuOpen}
                        sx={{
                            marginTop: '.8em', paddingTop: '0px', paddingBottom: '0px', textAlign: 'start', flexGrow: 1, color: '#000',
                            ...(selectedWhoWeAre === "who-we-are" ||
                                selectedWhoWeAre === "leadership-governance" ||
                                selectedWhoWeAre === "our-business" ||
                                selectedWhoWeAre === "social-impact" ||
                                selectedWhoWeAre === "sustainability" ? { color: '#017eff' } : {})
                        }}
                    >
                        <Typography sx={{ fontSize: 13, textAlign: 'start' }}>
                            Who We Are
                        </Typography>
                    </Button>
                </MenuItem>
                <MenuItem >
                    <Button
                        id="demo-customized-button"
                        variant="text"
                        disableElevation
                        endIcon={<ExpandMoreIcon />}
                        onClick={handleWhatWeDoMenuOpen}
                        sx={{
                            marginTop: '.8em', paddingTop: '0px', paddingBottom: '0px', textAlign: 'start', flexGrow: 1, color: '#000',
                            ...(selectedWhatWeDo === "ahead-of-ready" ||
                                selectedWhatWeDo === "domain-operations" ||
                                selectedWhatWeDo === "autonomy-ai" ||
                                selectedWhatWeDo === "cyber" ||
                                selectedWhatWeDo === "deference-capability" ||
                                selectedWhatWeDo === "transformative-technology" ? { color: '#017eff' } : {})
                        }}

                    >
                        <Typography sx={{ fontSize: 13, textAlign: 'start' }}>
                            What We Do
                        </Typography>
                    </Button>
                </MenuItem>
                <MenuItem >
                    <Button
                        id="demo-customized-button"
                        variant="text"
                        disableElevation
                        onClick={() => window.location.href = "/news"}
                        sx={{ marginTop: '.8em', paddingTop: '0px', paddingBottom: '0px', textAlign: 'start', justifyContent: 'start', flexGrow: 1, color: '#000', ...(selectedNews === "news" ? { color: '#017eff' } : {}) }}
                    >
                        <Typography sx={{ fontSize: 13, textAlign: 'start' }}>
                            News
                        </Typography>
                    </Button>
                </MenuItem>
                <MenuItem>
                    <Button
                        id="demo-customized-button"
                        variant="text"
                        disableElevation
                        onClick={() => window.location.href = "/career"}
                        sx={{ marginTop: '.8em', paddingTop: '0px', paddingBottom: '0px', textAlign: 'start', justifyContent: 'start', flexGrow: 1, color: '#000', ...(selectedContactUs === "contactUs" ? { color: '#017eff' } : {}) }}
                    >
                        <Typography sx={{ fontSize: 13, textAlign: 'start' }}>
                            Contact Us
                        </Typography>
                    </Button>
                </MenuItem>
                {/* <MenuItem>
                    <Button
                        id="demo-customized-button"
                        variant="text"
                        disableElevation
                        onClick={handleLanguageMenuOpen}
                        sx={{ marginTop: '.8em', paddingTop: '0px', paddingBottom: '0px', textAlign: 'start', justifyContent: 'start', flexGrow: 1, color: '#000' }}
                        endIcon={<ExpandMoreIcon />}
                    >
                        <Typography sx={{ fontSize: 13, textAlign: 'start' }}>
                            Language
                        </Typography>
                    </Button>
                </MenuItem> */}
            </Menu>

           
        </Box>

    )
}

export default MobileAppbarComponent