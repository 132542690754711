import React from "react";
import { Box, Typography } from "@mui/material";
import useWidth from "../../../../hooks/useWidth";
import SoftwareDevelopmentBanner from "../../../../assets/images/services/SoftwareDevelopment.png";
import SoftwareDevelopmentComponent from "../components/software-development-component";


const SoftwareDevelopmentScreen = () => {

    const breakpoints = useWidth();

    return (
        <Box>
            <Box sx={{ textAlign: 'start' }}>
                <div class="container-fluid container-xxxl">
                    <div class="bannerOverlayArea pb-3 pb-md-0">
                        <div className="bannerOverlayMinimizeImage bannerFull"
                            style={{
                                backgroundImage: `url(${SoftwareDevelopmentBanner})`,
                                backgroundPosition: '50% 30%',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover'
                            }}>
                        </div>
                        <div class="bannerOverlayText col-12  pt-2 pb-3 pb-md-5 pl-md-5" >
                            <div class="bannerTextShortTitle"></div>
                            <div class="bannerTextSubTitle d-none d-md-block">
                                <Typography className="bannerTextShortTitle" sx={{ fontSize: { xs: 30, sm: 40, md: 50 } }}>Software Development</Typography>
                            </div>
                            <Typography className="bannerTextLongTitle d-none d-md-block" sx={{ fontSize: { xs: 28, sm: 34 }, mt: 2 }}>
                                Innovative Solutions Tailored to Your Business Needs
                            </Typography>
                        </div>
                    </div>
                </div>
            </Box>

            <Box sx={{ mb: 10 }} />

            <SoftwareDevelopmentComponent breakpoints={breakpoints} />

        </Box>
    )
}

export default SoftwareDevelopmentScreen