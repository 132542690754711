import { Box, Grid, Link, Typography } from "@mui/material";
import logo from "../assets/logo/nextix-logo.png";

import facebookIcon from "../assets/icons/social-media/facebook.png"
import twitterIcon from "../assets/icons/social-media/twitter.png"
import youtubeIcon from "../assets/icons/social-media/youtube .png"
import instagramIcon from "../assets/icons/social-media/instagram.png"
import linkedinIcon from "../assets/icons/social-media/linkedin.png"
import { useEffect, useState } from "react";
import useWidth from "../hooks/useWidth";
import FooterSocialIconsComponent from "./footer-social-icons.component";


const translations = {
    english: {
        year: "© 2024",
        company: "Nextix Incorporated.",
        allRightsReserved: "All Rights Reserved.",
        privacy: "Privacy",
        termsOfUse: "Terms of Use",
        dataProtection: "Data Protection",
        cookies: "Cookies",
        socialMedia: "Social Media"
    },
    korean: {
        year: "© 2024",
        company: "Nextix 주식회사.",
        allRightsReserved: "모든 권리 보유.",
        privacy: "개인정보 보호",
        termsOfUse: "이용 약관",
        dataProtection: "데이터 보호",
        cookies: "쿠키",
        socialMedia: "소셜 미디어"
    },
    spanish: {
        year: "© 2024",
        company: "Nextix Incorporated.",
        allRightsReserved: "Todos los derechos reservados.",
        privacy: "Privacidad",
        termsOfUse: "Términos de uso",
        dataProtection: "Protección de datos",
        cookies: "Cookies",
        socialMedia: "Redes sociales"
    }
};



const FooterComponent = () => {

    const breakpoints = useWidth();

    const [language, setLanguage] = useState("english");

    useEffect(() => {
        // Retrieve the selected language from local storage
        const storedLanguage = localStorage.getItem('language');

        // Set the language from local storage if it exists
        if (storedLanguage) {
            setLanguage(storedLanguage);
        }
    }, []);

    return (
        <Box class="container" sx={{marginTop: '-80px'}}>
            {/* <Grid container sx={{padding: 0}}>
                    <Grid item xs={12} sm={7} sx={{padding: 0}}>
                        <Grid container sx={{padding: 0}}>
                            <Grid item xs={12} sm={3.5} sx={{textAlign: 'start'}}>
                                <img
                                    alt=""
                                    src={logo}
                                    style={{
                                        height: breakpoints === 'xs' ? 50 : 60,
                                        verticalAlign: "middle",
                                        cursor: "pointer",
                                        imageRendering: "pixelated",
                                        imageRendering: "-webkit-optimize-contrast",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} sx={{textAlign: 'start', mt: breakpoints === 'xs' ? 3 : 0}}>
                                <Typography>{translations[language].year}</Typography>
                                <Typography sx={{mt:1}}>{translations[language].company}</Typography>
                                <Typography sx={{mt:1}}>{translations[language].allRightsReserved}</Typography>
                            </Grid>
                   
                            <Grid item xs={12} sm={4} sx={{textAlign: 'start', mt: breakpoints === 'xs' ? 3 : 0}}>
                                <Typography onClick={() => window.location.href = "/contact/disclaimer/privacy"} sx={{cursor: 'pointer'}} >{translations[language].privacy}</Typography>
                                <Typography onClick={() => window.location.href = "/contact/disclaimer"} sx={{mt:1, cursor: 'pointer'}}>{translations[language].termsOfUse}</Typography>
                                <Typography onClick={() => window.location.href = "/contact/radix-group-incorporated-privacy-notice"} sx={{mt:1, cursor: 'pointer'}}>{translations[language].dataProtection}</Typography>
                                <Typography onClick={() => window.location.href = "/contact/cookies"} sx={{mt:1, cursor: 'pointer'}}>{translations[language].cookies}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={5} sx={{textAlign: 'start', mt: breakpoints === 'xs' ? 3 : 0}}>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <Typography>{translations[language].socialMedia}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} sx={{mt: 2}} >
                                <Box sx={{display: 'flex'}}>
                                    <a  href="https://www.facebook.com" target="_blank" ><img src={facebookIcon} style={{width: breakpoints === 'xs' ? 28 : 38, height:  breakpoints === 'xs' ? 28 : 38}}  /></a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a  href="https://twitter.com" target="_blank" ><img src={twitterIcon} style={{width:  breakpoints === 'xs' ? 28 : 38, height:  breakpoints === 'xs' ? 28 : 38}} /></a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a  href="https://www.youtube.com" target="_blank" ><img src={youtubeIcon} style={{width:  breakpoints === 'xs' ? 28 : 38, height:  breakpoints === 'xs' ? 28 : 38}} /></a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a  href="https://www.instagram.com" target="_blank" ><img src={instagramIcon} style={{width:  breakpoints === 'xs' ? 28 : 38, height:  breakpoints === 'xs' ? 28 : 38}} /></a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a  href="https://www.linkedin.com" target="_blank" ><img src={linkedinIcon} style={{width:  breakpoints === 'xs' ? 28 : 38, height:  breakpoints === 'xs' ? 28 : 38}} /></a>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> */}
            <footer class="c-uhff context-uhf"  >

                <nav class="c-uhff-nav" aria-label="Footer Resource links">
                    <div class="c-uhff-nav-row" style={{ textAlign: 'start' }}>
                        <div class="c-uhff-nav-group">
                            <div class="c-heading-4" role="heading" aria-level="2" style={{ padding: '6px 0 4px' }}>Offices</div>
                            <ul class="c-list f-bare" >

                                <li class="c-uhff-link">
                                    <a class="c-uhff-link">Makati City, Philippines</a>
                                </li>

                                <li class="c-uhff-link">
                                    <a class="c-uhff-link">Cebu City, Philippines</a>
                                </li>

                            </ul>

                        </div>
                    </div>

                    <div class="c-uhff-nav-row" style={{ textAlign: 'start' }}>
                        <div class="c-uhff-nav-group">
                            <div class="c-heading-4" role="heading" aria-level="2" style={{ padding: '6px 0 4px' }} >We Are Nextix</div>
                            <ul class="c-list f-bare" >

                                <li>
                                    <a class="c-uhff-link" href="/who-we-are"  >Our Story</a>
                                </li>

                                <li class="c-uhff-link">
                                    <a class="c-uhff-link" href="/who-we-are#mission-vision" >Mission and Vision</a>
                                </li>

                                <li class="c-uhff-link">
                                    <a class="c-uhff-link" href="/who-we-are#leadership-team" >Leadership Team</a>
                                </li>

                                <li class="c-uhff-link">
                                    <a class="c-uhff-link" href="/career" >Careers</a>
                                </li>
                            </ul>

                        </div>
                    </div>

                    <div class="c-uhff-nav-row" style={{ textAlign: 'start' }}>
                        <div class="c-uhff-nav-group">
                            <div class="c-heading-4" role="heading" aria-level="2" style={{ padding: '6px 0 4px' }}>Services</div>
                            <ul class="c-list f-bare" >

                                <li>
                                    <a aria-label="Verify" class="c-uhff-link" href="/services/digital-transformation">
                                        Digital Transformation
                                    </a>
                                </li>

                                <li class="c-uhff-link">
                                    <a class="c-uhff-link" href="/services/software-development" >Software Development</a>
                                </li>

                                <li class="c-uhff-link">
                                    <a class="c-uhff-link" href="/services/cloud-services" >Cloud Services</a>
                                </li>

                                <li class="c-uhff-link">
                                    <a class="c-uhff-link" href="/services/data-analytics" >Data Analytics</a>
                                </li>

                                <li class="c-uhff-link">
                                    <a class="c-uhff-link" href="/services/cybersecurity" >Cybersecurity</a>
                                </li>

                            </ul>

                        </div>
                    </div>

                    <div class="c-uhff-nav-row" style={{ textAlign: 'start' }}>
                        <div class="c-uhff-nav-group">
                            <div class="c-heading-4" role="heading" aria-level="2" style={{ padding: '6px 0 4px' }}>Solutions</div>
                            <ul class="c-list f-bare" >

                                <li>
                                    <a aria-label="Industry Solutions" class="c-uhff-link" href="/what-we-do" >
                                        Industry Solutions
                                    </a>
                                </li>

                                {/* <li>
                                    <a aria-label="Customs Solution" class="c-uhff-link">
                                        Customs Solution
                                    </a>
                                </li> */}

                                {/* <li>
                                    <a aria-label="Success Stories" class="c-uhff-link" >
                                        Success Stories
                                    </a>
                                </li> */}

                            </ul>

                        </div>
                    </div>

                    <div class="c-uhff-nav-row" style={{ textAlign: 'start' }}>
                        <div class="c-uhff-nav-group">
                            <div class="c-heading-4" role="heading" aria-level="2" style={{ padding: '6px 0 4px' }}>Resources</div>
                            <ul class="c-list f-bare">

                                <li>
                                    <a aria-label="Blog" class="c-uhff-link" href="/resources">
                                        Blog
                                    </a>
                                </li>

                                <li>
                                    <a aria-label="Whitepapers" class="c-uhff-link" href="/resources#whitepapers" >
                                        Whitepapers
                                    </a>
                                </li>
                                
                                {/* <li>
                                    <a aria-label="Webinars" class="c-uhff-link" >
                                        Webinars
                                    </a>
                                </li> */}



                            </ul>

                        </div>
                    </div>

                    <div class="c-uhff-nav-row" style={{ textAlign: 'start' }}>
                        <div class="c-uhff-nav-group">
                            <div class="c-heading-4" role="heading" aria-level="2" style={{ padding: '6px 0 4px' }}>Contact Us</div>
                            <ul class="c-list f-bare">

                                <li id="c-uhff-footer_privacyandcookies">
                                    <a class="c-uhff-link" href="/join-our-team">
                                        Contact Form
                                    </a>
                                </li>

                                <li id="c-uhff-footer_termsofuse">
                                    <a class="c-uhff-link" href="/who-we-are#office-locations">
                                        Office Locations
                                    </a>
                                </li>

                                {/* <li class=" x-hidden" id="c-uhff-footer_managecookies">
                                    <a class="c-uhff-link" href="/faq">
                                        Support
                                    </a>
                                </li> */}

                            </ul>
                        </div>
                    </div>
                </nav>

                <div class="c-uhff-base" style={{ marginTop: '120px', padding: '30px 11.5% 16px' }}>
                    <nav aria-label="OVCODE  links">
                        <ul class="c-list f-bare">

                            <li id="c-uhff-footer_contactus">
                                <a class="c-uhff-link" href="/contact-us">
                                    Contact Us</a>
                            </li>

                            <li id="c-uhff-footer_privacyandcookies">
                                <a class="c-uhff-link" href="/privacy-policy">
                                    Privacy
                                </a>
                            </li>

                            <li id="c-uhff-footer_termsofuse">
                                <a class="c-uhff-link" href="/terms-of-service">
                                    Terms of use
                                </a>
                            </li>

                            <li class=" x-hidden" id="c-uhff-footer_managecookies">
                                <a class="c-uhff-link" href="/faq">
                                    FAQ
                                </a>
                            </li>

                            <li>©2023 All Rights Reserved</li>

                        </ul>
                    </nav>
                </div>

            </footer>

            <Box sx={{ mt: 2 }} />

        </Box >
    )
}

export default FooterComponent