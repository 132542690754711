import React from "react";
import { Box, Typography } from "@mui/material";
import useWidth from "../../../../hooks/useWidth";
import DataAnalyticsBanner from "../../../../assets/images/services/DataAnalytics.png";
import DataAnalyticsComponent from "../components/data-analytics-component";


const DataAnalyticsScreen = () => {

    const breakpoints = useWidth();

    return (
        <Box>
            <Box sx={{ textAlign: 'start' }}>
            <div class="container-fluid container-xxxl">
                    <div class="bannerOverlayArea pb-3 pb-md-0">
                        <div className="bannerOverlayMinimizeImage bannerFull"
                            style={{
                                backgroundImage: `url(${DataAnalyticsBanner})`,
                                backgroundPosition: '50% 50%',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover'
                            }}>
                        </div>
                        <div class="bannerOverlayText col-12  pt-2 pb-3 pb-md-5 pl-md-5" >
                            <div class="bannerTextShortTitle"></div>
                            <div class="bannerTextSubTitle d-none d-md-block">
                                <Typography className="bannerTextShortTitle" sx={{ fontSize: { xs: 30, sm: 40, md: 50 } }}>Data Analytics</Typography>
                            </div>
                            <Typography className="bannerTextLongTitle d-none d-md-block" sx={{ fontSize: { xs: 28, sm: 34 }, mt: 2 }}>
                                Unlock the Power of Your Data
                            </Typography>
                        </div>
                    </div>
                </div>
            </Box>

            <Box sx={{ mb: 10 }} />

            <DataAnalyticsComponent breakpoints={breakpoints} />


        </Box>
    )
}

export default DataAnalyticsScreen